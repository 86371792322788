import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function BackButton() {
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = () => {
        if (location.pathname === "/") {
            navigate("/");
        } else {
            navigate(-1);
        }
    };

    return (
        <button onClick={handleClick}>Back</button>
    );
}

export default BackButton;