import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, CircularProgress, Box } from '@mui/material';
import axios from 'axios';
import domain from '../../util/domain';

const SearchDropdown = ({ defaultOption, onBatchChange }) => {
    const [searchInput, setSearchInput] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(defaultOption); // State to hold the selected option
    console.log(selectedOption);
    // Fetch data from the API when searchInput changes
    useEffect(() => {
        if (searchInput === '') {
            setOptions([]);
            return;
        }

        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${domain}/branch/search/${searchInput}`);
                setOptions(response.data || []); // Adjust based on the API response structure
            } catch (error) {
                console.error('Error fetching data:', error);
                setOptions([]);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [searchInput]);

    const handleInputChange = (event, newInputValue) => {
        const upperCaseValue = newInputValue.toUpperCase(); // Convert input to uppercase
        setSearchInput(upperCaseValue);

        // Check if the input exactly matches one of the options
        const exactMatch = options.find(
            (option) => option.branch === upperCaseValue
        );

        if (exactMatch) {
            setSelectedOption(exactMatch);
            onBatchChange(exactMatch);
        } else {
            setSelectedOption(null);
        }
    };

    const handleChange = (event, newValue) => {
        const exactMatch = options.find(
            (option) => option.branch === newValue
        );

        if (exactMatch) {
            setSelectedOption(exactMatch);
        } else {
            setSelectedOption(null);
        }
    };

    return (
        <Box sx={{ margin: '0 auto' }}>
            <Autocomplete
                freeSolo
                options={options.map((option) => option.branch)}
                inputValue={searchInput}
                onInputChange={handleInputChange}
                onChange={handleChange}
                value={selectedOption && selectedOption.branch} // Bind the selected option to the autocomplete
                loading={loading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Branch"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
                sx={{
                    backgroundColor: '#fff',
                }}
            />
        </Box>
    );
};

export default SearchDropdown;