import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import List from '@mui/joy/List';
import axios from 'axios';
import domain from "../../util/domain";
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import HodDetailsList from './visits/details/HodDetailsList';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';

export default function CollegeHodTpoDetails() {
    const navigate = useNavigate();
    const location = useLocation();
    const [hods, setHods] = useState(null);
    const [tpos, setTpos] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const { visit } = location.state || {};
    useEffect(() => {
        loadHodsandTpos();
        // eslint-disable-next-line
    }, []);

    function loadHodsandTpos() {
        axios.get(`${domain}/hods?college_id=${visit.id}`)
            .then((response) => {
                console.log("response.data:HOD ", response.data);
                setHods(response.data);
            })
            .catch((error) => {
                console.log("CollegeHodTpoDetails: useEffect: error: ", error);
                setHods([]);
            });
        axios.get(`${domain}/tpos?college_id=${visit.id}`)
            .then((response) => {
                console.log("response.data:TPO ", response.data);
                setTpos(response.data);
            })
            .catch((error) => {
                console.log("CollegeHodTpoDetails: useEffect: error: ", error);
                setTpos([]);
            });
    }

    function renderHods() {
        return hods.map((hod, index) => (
            <HodDetailsList key={index} hodDetails={hod} showDetails={false} canEdit={false} canDelete={false} />
        ));
    }

    function deleteTpo(tpoItem) {
        console.log("Delete TPO: ", tpoItem);
        setTpos(tpos.filter((tpo) => tpo.id !== tpoItem.id));
        axios.delete(`${domain}/tpos/${tpoItem.id}`)
            .then((response) => {
                console.log("response: ", response);
            })
            .catch((error) => {
                console.log("error: ", error);
            });
    }

    function renderTpos() {
        return tpos.map((tpo, index) => (
            <HodDetailsList key={index} hodDetails={tpo} showDetails={false} deleteAction={deleteTpo} canDelete={false} canEdit={false} />
        ));
    }

    const deleteVisit = () => {
        axios.delete(`${domain}/visits/${visit.id}`)
            .then((response) => {
                console.log("response: ", response);
                navigate("/visits");
            })
            .catch((error) => {
                console.log("error: ", error);
            });
    }


    const handleDeleteConfirm = () => {
        setOpenDeleteModal(false);
        deleteVisit();
    };

    const handleCreateVisit = () => {

        navigate("/visits/add", { state: { college: visit } });
    }



    return (
        <>
            <List>
                <ListItem><ListItemContent><Button variant="outlined" onClick={handleCreateVisit} startIcon={<AddIcon />}>Create new visit with this college</Button></ListItemContent>
                </ListItem>
                <ListItem><ListItemContent><Typography level="title-sm">HOD Details</Typography></ListItemContent>
                </ListItem>
                {hods === null ? (
                    <ListItem>
                        <ListItemContent>
                            <Typography level="body-sm">Loading HOD Details...</Typography>
                        </ListItemContent>
                    </ListItem>
                ) : hods.length > 0 ? (
                    renderHods()
                ) : (
                    <ListItem>
                        <ListItemContent>
                            <Typography level="body-sm">No HOD details available</Typography>
                        </ListItemContent>
                    </ListItem>
                )}

                <ListItem><ListItemContent><Typography level="title-sm">TPO Details</Typography></ListItemContent>
                </ListItem>

                {tpos === null ? (
                    <ListItem>
                        <ListItemContent>
                            <Typography level="body-sm">Loading TPO Details...</Typography>
                        </ListItemContent>
                    </ListItem>
                ) : tpos.length > 0 ? (
                    renderTpos()
                ) : (
                    <ListItem>
                        <ListItemContent>
                            <Typography level="body-sm">No TPO details available</Typography>
                        </ListItemContent>
                    </ListItem>
                )}
            </List>

            <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                <ModalDialog
                    variant="outlined"
                    role="alertdialog"
                    aria-labelledby="alert-dialog-modal-title"
                    aria-describedby="alert-dialog-modal-description"
                >
                    <Typography id="alert-dialog-modal-title" component="h2">
                        Confirm Deletion
                    </Typography>
                    <Typography id="alert-dialog-modal-description" textColor="text.tertiary">
                        Are you sure you want to delete this visit? This action cannot be undone.
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2 }}>
                        <Button variant="plain" color="neutral" onClick={() => setOpenDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="solid" color="danger" onClick={handleDeleteConfirm}>
                            Delete Visit
                        </Button>
                    </Box>
                </ModalDialog>
            </Modal>
        </>
    )
}

