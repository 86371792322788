import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import createCustomQRCode from '../../inputs/createCustomQRCode';
import html2pdf from 'html2pdf.js';
import axios from 'axios';
import domain from "../../../util/domain";


export default function GeneratePDF() {
    const [imgData, setImgData] = useState(null);
    const [template, setTemplate] = useState('');
    const contentRef = useRef(null);
    const location = useLocation();
    const visitData = location.state?.visit;
    const [students, setStudents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedYop, setSelectedYop] = useState(new Date().getFullYear() - 3);

    useEffect(() => {
        // Load HTML template
        let modifiedHtml = 'NO STUDENTS FOUND';
        if (students.length > 0) {
            fetch('/pdfpage1.html')
                .then(response => response.text())
                .then(html => {
                    // Replace content with visitData
                    modifiedHtml = html;
                    if (visitData) {
                        modifiedHtml = modifiedHtml
                            .replace('{{college_name}}', visitData.college_name || '')
                            .replace('{{college_address}}', visitData.college_address || '')
                        // Add more replacements as needed
                    }
                    let imagebox = '';
                    for (let i = 0; i < students.length; i++) {
                        if (i === 3) {
                            break;
                        }
                        let student = students[i];
                        let photoSrc = '';
                        try {
                            if (student.photo && student.photo.data) {
                                // Convert Buffer data array to base64
                                const uint8Array = new Uint8Array(student.photo.data);
                                const base64String = btoa(String.fromCharCode.apply(null, uint8Array));
                                photoSrc = `data:image/jpeg;base64,${base64String}`;
                            }
                        } catch (error) {
                            console.error('Error processing photo:', error);
                            photoSrc = ''; // Set to default image if there's an error
                        }

                        imagebox += `<div class="photo-boxes"><img src="${photoSrc}" width="60" height="60" onerror="this.src='default-avatar.png';" />
                        <p> <span class="name">${student.name}</span><br />
                            Branch: ${student.specialization} <br/> YOP:${student.yop}<br />
                            placed in <br />
                            <span class="name">${student.companyid}</span>
                        </p></div>`;
                    }
                    modifiedHtml = modifiedHtml.replace('{{photoBox}}', imagebox);
                    if (imgData && students.length != 3) {
                        modifiedHtml = modifiedHtml.replace('{{qrcode}}', `<div class="photo-boxes"><img width="100%" src="${imgData}" alt="QR Code" /><span class="name">Scan here for Online Test</span></div>`);
                    } else {
                        modifiedHtml = modifiedHtml.replace('{{qrcode}}', '');
                    }
                    setTemplate(modifiedHtml);

                })
                .catch(err => console.error('Error loading template:', err));

            if (students.length > 3) {
                let modifiedHtml2 = template;
                fetch('/pdfpage2.html')
                    .then(response => response.text())
                    .then(html => {
                        modifiedHtml2 += html;
                        let imagebox = '';
                        for (let i = 3; i < students.length; i++) {
                            let student = students[i];
                            let photoSrc = '';
                            try {
                                if (student.photo && student.photo.data) {
                                    // Convert Buffer data array to base64
                                    const uint8Array = new Uint8Array(student.photo.data);
                                    const base64String = btoa(String.fromCharCode.apply(null, uint8Array));
                                    photoSrc = `data:image/jpeg;base64,${base64String}`;
                                }
                            } catch (error) {
                                console.error('Error processing photo:', error);
                                photoSrc = ''; // Set to default image if there's an error
                            }

                            imagebox += `<div class="photo-boxes"><img src="${photoSrc}" width="60" height="60" onerror="this.src='default-avatar.png';" />
                        <p> <span class="name">${student.name}</span><br />
                            Branch: ${student.specialization} <br/> YOP:${student.yop}<br />
                            placed in <br />
                            <span class="name">${student.companyid}</span>
                        </p></div>`;
                        }
                        modifiedHtml2 = modifiedHtml2.replace('{{photoBox}}', imagebox);
                        if (imgData) {
                            modifiedHtml2 = modifiedHtml2.replace('{{qrcode}}', `<div class="photo-boxes"><img width="100%" src="${imgData}" alt="QR Code" /><span class="name">Scan here for Online Test</span></div>`);
                        } else {
                            modifiedHtml2 = modifiedHtml2.replace('{{qrcode}}', '');
                        }
                        modifiedHtml += modifiedHtml2;
                    })
                    .catch(err => console.error('Error loading template:', err));
                setTemplate(modifiedHtml);

            }
        }
    }, [visitData, students]);

    useEffect(() => {
        const loadQRCode = async () => {
            const data = await createCustomQRCode(`https://www.vectorindia.org/registration?utm_source=${visitData?.college_code}&utm_medium=pdf&utm_campaign=vectorcv`);
            setImgData(data);
        };
        loadQRCode();
        setIsLoading(true);
        //get students for the college
        axios.get(`${domain}/pdf?collegeCode=${visitData?.college_code}&yop=${selectedYop}`)
            .then((response) => {
                //for testing add more students
                // const morestudents = response.data.concat(response.data);

                console.log("Studentdetails ", response.data);
                setStudents(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("pdf students: useEffect: error: ", error);
                setStudents([]);
                setIsLoading(false);
            });

        //select b.studentid,b.name,b.yop,b.qualification,b.specialization,b.ugcollegecode,c.collegename,e.companyid from studentbatch a,registeredstudent b, college c, studentcampus d, companycampus e where b.studentid=a.studentid and b.ugcollegecode=c.collegecode and d.studentid=b.studentid and d.selected=1 and d.campusid=e.campusid and c.collegecode='C-8003' and d.campusid not like 'Self%';
    }, [visitData]);

    const handleDownloadPDF = () => {
        const element = contentRef.current;
        const options = {
            margin: 0.3,
            filename: `${visitData?.college_code}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 5, logging: true, useCORS: true },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
        };
        html2pdf().set(options).from(element).save();

    };

    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let year = currentYear; year >= currentYear - 10; year--) {
            years.push(year);
        }
        return years;
    };

    const updatePDF = () => {
        console.log("updatePDF: selectedYop: ", selectedYop);
        setStudents([]);
        setIsLoading(true);
        axios.get(`${domain}/pdf?collegeCode=${visitData?.college_code}&yop=${selectedYop}`).then((response) => {
            //for testing add more students
            // const morestudents = response.data.concat(response.data);
            // const morestudents = response.data.join(response.data);
            console.log("Studentdetails ", response.data);
            setStudents(response.data);
            setIsLoading(false);
        })
            .catch((error) => {
                console.log("pdf students: useEffect: error: ", error);
                setStudents([]);
                setIsLoading(false);
            });
    }
    return (

        <div>
            <div>
                { }
                <span>YOP : </span>
                <select
                    value={selectedYop}
                    onChange={(e) => setSelectedYop(parseInt(e.target.value))}
                    style={{
                        padding: '5px',
                        marginRight: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ccc'
                    }}
                >
                    {generateYearOptions().map(year => (
                        <option key={year} value={year}>{year}</option>
                    ))}
                </select>
                <button onClick={updatePDF}>Update</button>
                <button
                    onClick={handleDownloadPDF}
                    style={{
                        marginTop: '20px',
                        padding: '10px 20px',
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}
                >
                    Download PDF
                </button>
            </div>
            {isLoading ? <div><h2>Loading...</h2></div> : (students.length === 0) ? "No Students found" : ""}
            <div ref={contentRef}>
                {/* Render the template */}
                <div dangerouslySetInnerHTML={{ __html: template }} />
            </div>

        </div>
    );
}
