import QRCode from 'qrcode';

async function createCustomQRCode(text) {
    try {
        // Generate QR code as data URL
        const qrDataUrl = await QRCode.toDataURL(text, {
            color: {
                dark: '#000000',
                light: '#FFFFFF'
            },
            margin: 2,
            scale: 10,
            width: 200,
            type: 'image/jpeg'
        });

        return qrDataUrl;
    } catch (error) {
        console.error("Error generating QR code:", error);
        throw error;
    }
}

export default createCustomQRCode;