import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, CircularProgress, Box } from '@mui/material';
import axios from 'axios';
import domain from '../../util/domain';

const SearchCollege = ({ defaultOption, college, onCollegeChange }) => {
    const [searchInput, setSearchInput] = useState('');
    const [options, setOptions] = useState(college ? [college] : []);
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(defaultOption || undefined); // State to hold the selected option
    console.log("in search college options: ", options);
    // Fetch data from the API when searchInput changes
    useEffect(() => {
        if (searchInput === '') {
            if (options.length < 1) {
                setOptions([]);
            } else {
                console.log("useEffect: setting default option");
                setSelectedOption(options[0]);
                onCollegeChange(options[0]);
                setSearchInput(`${options[0].college_name}-${options[0].college_code}`);
            }
            return;
        }

        const fetchData = async () => {
            if (searchInput.length < 3) {
                return;
            }
            setLoading(true);
            try {
                const response = await axios.get(`${domain}/college/search/${searchInput}`);
                setOptions(response.data || []); // Adjust based on the API response structure
            } catch (error) {
                console.error('Error fetching data:', error);
                setOptions([]);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [searchInput]);

    const handleInputChange = (event, newInputValue) => {
        const upperCaseValue = newInputValue.toUpperCase(); // Convert input to uppercase
        setSearchInput(upperCaseValue);

        // Check if the input exactly matches one of the options
        const exactMatch = options.find(
            (option) => `${option.college_name}-${option.college_code}` === newInputValue
        );


        if (exactMatch) {
            setSelectedOption(exactMatch);
            onCollegeChange(exactMatch);
        } else {
            setSelectedOption(null);
        }
    };

    const handleChange = (event, newValue) => {
        console.log("newValue: ", newValue);
        const exactMatch = options.find(
            (option) => `${option.college_name}-${option.college_code}` === newValue
        );
        if (exactMatch) {
            setSelectedOption(exactMatch);
            onCollegeChange(exactMatch);

        } else {
            setSelectedOption(null);
        }
    };

    return (
        <Box sx={{ margin: '0 auto' }}>
            <Autocomplete
                freeSolo
                options={options.map((option) => `${option.college_name}-${option.college_code}`)}
                inputValue={searchInput}
                onInputChange={handleInputChange}
                onChange={handleChange}
                value={selectedOption && `${selectedOption.college_name}-${selectedOption.college_code}`} // Bind the selected option to the autocomplete
                loading={loading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select a College"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
                sx={{
                    backgroundColor: '#fff',
                }}
            />
        </Box>
    );
};

export default SearchCollege;