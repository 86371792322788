import React from 'react';
import Typography from '@mui/joy/Typography';

function DateFormatter({ dateString }) {
    const dateObject = new Date(dateString);

    // Format date to Indian format with MMM month (e.g., "14/Aug/2024")
    const formattedDate = dateObject.toLocaleDateString('en-IN', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
    });

    return <Typography level="body-sm" noWrap>{formattedDate}</Typography>;
}

export default DateFormatter;