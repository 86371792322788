import React, { useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Card from '@mui/joy/Card';
import FoldableInput from '../../inputs/FoldableInput';
import Typography from '@mui/joy/Typography';
import Divider from '@mui/joy/Divider';
import Checkbox from '@mui/joy/Checkbox';
import Sheet from '@mui/joy/Sheet';
import ImageCapture from '../../inputs/ImageCapture'; // Import the new component
import axios from 'axios';
import domain from "../../../util/domain";
import CapturedLocation from '../../inputs/CapturedLocation';
import UserContext from "../../../context/UserContext";
import AddIcon from '@mui/icons-material/Add';
import SearchCollege from '../../inputs/SearchCollege';

export default function AddVisit() {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();
    const { college } = location.state || {};
    const added_user_id = user.id;
    const [college_id, setCollege_id] = useState(college && college.id ? college.id : "");
    console.log("college_id: ", college_id);
    const [visit_date] = useState(new Date().toLocaleDateString('en-IN', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
    }).replace(/ /g, '-'));
    const [isposted_noticeboard, setIsposted_noticeboard] = useState(0);
    const [remarks, setRemarks] = useState("");
    const [capturedPhoto, setCapturedPhoto] = useState(null);
    const [geoLocation, setGeoLocation] = useState({ lat: 0, lon: 0 });

    const handleCapture = (photo) => {
        setCapturedPhoto(photo);
    };

    const selectSearchCollege = (college) => {
        console.log("Selected College: ", college);
        setCollege_id(college.id);
    }
    const updateLocation = (location) => {
        console.log("location: ", location);
        setGeoLocation(location);
    };

    const cancelAction = (e) => {
        e.preventDefault();
        navigate(-1);
    }
    const addVisit = (e) => {
        e.preventDefault();
        axios.post(`${domain}/visits/`, {
            visit_date,
            college_id,
            added_user_id,
            isposted_noticeboard,
            remarks,
            newPhoto: capturedPhoto,
            longitude: geoLocation.lon,
            latitude: geoLocation.lat
        })
            .then((response) => {
                console.log("Visit ADD: response: ", response.data);
                navigate('/visits');

            })
            .catch((error) => {
                console.error("Visit ADD error: ", error);
                alert("Error adding visit", error);
            });
    }

    return (
        <form>
            <Card>
                <Typography level="title-lg" startDecorator={<AddIcon />}>
                    {`Visit Details`}
                </Typography>
                <Divider inset="none" />
                {/* 
                <Sheet sx={{ height: '44px', paddingBottom: '10px' }}>
                    <SearchDropdown defaultOption={{ branch }} onBatchChange={batchCanged} />
                </Sheet> */}
                <FoldableInput inputType='text' labelText={`Visit Date`} placeHolderText={`Visiting Date`} value={visit_date} disabled />
                <Sheet sx={{ height: '44px', paddingBottom: '10px' }}>
                    <SearchCollege onCollegeChange={selectSearchCollege} college={college} />
                </Sheet>
                <FoldableInput inputType='textarea' labelText={`Remarks`} placeHolderText={`Remarks`} value={remarks} onChange={(e) => setRemarks(e.target.value)} />
                <Sheet variant="outlined" sx={{ height: '44px' }}>
                    <Checkbox overlay label="Is Posted to Notice Board?"
                        sx={{ alignContent: 'center', paddingLeft: '10px', marginTop: '13px' }}
                        checked={(isposted_noticeboard === 0) ? false : true}
                        onChange={(e) => {
                            setIsposted_noticeboard((e.target.checked) ? 1 : 0);
                        }}
                    />
                </Sheet>
                <CapturedLocation onUpdateLocation={updateLocation} />
                {/* Image Capture Component */}
                <ImageCapture title={`College Photo`} onCapture={handleCapture} />
                <button type="button" onClick={cancelAction}>Cancel</button>
                <button type="submit" onClick={addVisit}>Save</button>
            </Card>
        </form>
    );
}