import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Typography,
    FormControl,
    FormLabel,
    Input,
    Select,
    Option,
    Button,
    Sheet,
    ListItem,
    ListItemButton,
    ListItemContent
} from '@mui/joy';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import domain from "../../../util/domain";
import { useNavigate, useLocation } from 'react-router-dom';

export default function CreatePDF() {
    const navigate = useNavigate();
    const location = useLocation();

    // Initialize state from localStorage or location state
    const [collegeName, setCollegeName] = useState(localStorage.getItem('pdfcollegeName') || '');
    const [collegeCode, setCollegeCode] = useState(localStorage.getItem('pdfcollegeCode') || '');
    const [selectedState, setSelectedState] = useState(localStorage.getItem('pdfselectedState') || '');
    const [selectedDistrict, setSelectedDistrict] = useState(localStorage.getItem('pdfselectedDistrict') || '');
    const [states, setStates] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [searchResults, setSearchResults] = useState(JSON.parse(localStorage.getItem('pdfsearchResults')) || []);

    useEffect(() => {
        fetchStates();
        // If coming back from CollegeHodTpoDetails, scroll to search results
        if (location.state && location.state.fromCollegeDetails) {
            const resultsElement = document.getElementById('search-results');
            if (resultsElement) {
                resultsElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    useEffect(() => {
        if (selectedState) {
            fetchDistricts(selectedState);
        }
    }, [selectedState]);

    const fetchStates = async () => {
        try {
            const response = await axios.get(`${domain}/search/api/states`);
            console.log(response.data);
            setStates([{ id: 0, name: 'Select State' }, ...response.data]);
        } catch (error) {
            console.error("Error fetching states:", error);
        }
    };

    const fetchDistricts = async (stateId) => {
        try {
            const response = await axios.get(`${domain}/search/api/districts/${stateId}`);
            setDistricts([{ id: 0, name: 'Select District' }, ...response.data]);
        } catch (error) {
            console.error("Error fetching districts:", error);
        }
    };

    const handleSearch = async () => {
        try {
            const stateId = selectedState == 0 ? 'null' : selectedState;
            const districtId = selectedDistrict == 0 ? 'null' : selectedDistrict;
            const response = await axios.get(`${domain}/search/api/colleges/search`, {
                params: {
                    collegeName,
                    collegeCode,
                    stateId: stateId,
                    districtId: districtId
                }
            });
            setSearchResults(response.data);
            // Save search parameters and results to localStorage
            localStorage.setItem('pdfcollegeName', collegeName);
            localStorage.setItem('pdfcollegeCode', collegeCode);
            localStorage.setItem('pdfselectedState', selectedState);
            localStorage.setItem('pdfselectedDistrict', selectedDistrict);
            localStorage.setItem('pdfsearchResults', JSON.stringify(response.data));
        } catch (error) {
            console.error("Error searching colleges:", error);
        }
    };

    const handleCollegeClick = (event, college) => {
        if (event.defaultPrevented) return;
        event.preventDefault();
        console.log("college:", college);
        navigate("/generatepdf", {
            state: {
                visit: college,
                fromSearch: true // Add this flag
            }
        });
    };

    return (
        <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
            <Typography level="h4" component="h1" sx={{ mb: 2 }}>
                College Search
            </Typography>
            <Sheet variant="outlined" sx={{ p: 2, borderRadius: 'sm' }}>
                <FormControl sx={{ mb: 2 }}>
                    <FormLabel>College Name</FormLabel>
                    <Input
                        value={collegeName}
                        onChange={(e) => setCollegeName(e.target.value)}
                    />
                </FormControl>
                <FormControl sx={{ mb: 2 }}>
                    <FormLabel>College Code</FormLabel>
                    <Input
                        value={collegeCode}
                        onChange={(e) => setCollegeCode(e.target.value)}
                    />
                </FormControl>
                <FormControl sx={{ mb: 2 }}>
                    <FormLabel>State</FormLabel>
                    <Select
                        value={selectedState}
                        onChange={(e, newValue) => setSelectedState(newValue)}
                    >
                        {states.map((state) => (
                            <Option key={state.id} value={state.id}>{state.name}</Option>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{ mb: 2 }}>
                    <FormLabel>District</FormLabel>
                    <Select
                        value={selectedDistrict}
                        onChange={(e, newValue) => setSelectedDistrict(newValue)}
                        disabled={!selectedState}
                    >
                        {districts.map((district) => (
                            <Option key={district.id} value={district.id}>{district.name}</Option>
                        ))}
                    </Select>
                </FormControl>
                <Button onClick={handleSearch} sx={{ mt: 2 }}>
                    Search
                </Button>
            </Sheet>
            {searchResults.length > 0 && (
                <Sheet id="search-results" variant="outlined" sx={{ mt: 4, p: 2, borderRadius: 'sm' }}>
                    <Typography level="h6" component="h2" sx={{ mb: 2 }}>
                        Search Results
                    </Typography>
                    {searchResults.map((college) => (
                        <ListItem key={college.id}>
                            <ListItemButton onClick={(e) => handleCollegeClick(e, college)}>
                                <ListItemContent>
                                    <Typography level="body-md">{college.college_name}</Typography>
                                    <Typography level="body-sm">Code: {college.college_code}</Typography>
                                    {college.college_address && <Typography level="body-sm">Address: {college.college_address}</Typography>}
                                    {college.univerisity_name && <Typography level="body-sm">University: {college.univerisity_name}</Typography>}
                                </ListItemContent>
                                <KeyboardArrowRightIcon />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </Sheet>
            )}
        </Box>
    );
}
