import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Typography from '@mui/joy/Typography';
import DF from "../../misc/DateFormatter";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import domain from "../../../util/domain";
import { Modal, Box, IconButton } from '@mui/joy'; // Importing components from Joy UI
import { keyframes } from '@emotion/react'; // Importing keyframes for animations
import CloseIcon from '@mui/icons-material/Close'; // Importing a close icon from Material-UI

export default function VisitsListView({ visit, showDetails, action, isExpanded }) {
    const [open, setOpen] = React.useState(false);
    const [imageSrc, setImageSrc] = React.useState('');
    const [startPosition, setStartPosition] = React.useState({ x: 0, y: 0 });
    const [isAnimating, setIsAnimating] = React.useState(false); // State to manage animation
    const [isClosing, setIsClosing] = React.useState(false); // State to manage closing
    const growAnimation = keyframes`
      0% {
        opacity: 0;
        transform: translate(${startPosition.x}px, ${startPosition.y}px) scale(0.1);
      }
      100% {
        opacity: 1;
        transform: translate(0, 0) scale(1);
      }
    `;

    const shrinkAnimation = keyframes`
      0% {
        opacity: 1;
        transform: translate(0, 0) scale(1);
      }
      100% {
        opacity: 0;
        transform: translate(${startPosition.x}px, ${startPosition.y}px) scale(0.1);
      }
    `;

    const handleClick = (e) => {
        e.preventDefault();
        const rect = e.target.getBoundingClientRect();
        const clickX = rect.left + rect.width / 2;
        const clickY = rect.top + rect.height / 2;

        setStartPosition({ x: clickX, y: clickY });
        const imgpath = e.target.currentSrc.replaceAll("/100/", "/");
        setImageSrc(imgpath);
        setIsClosing(false);
        setOpen(true);
        setTimeout(() => setIsAnimating(true), 10); // Start animation after ensuring open state
    };

    const handleClose = () => {
        setIsAnimating(false); // Trigger close animation
        setIsClosing(true); // Set closing state

        setTimeout(() => {
            setOpen(false); // Hide modal after animation completes
            setIsClosing(false); // Reset closing state
        }, 500); // Matches the duration of the animation
    };
    if (showDetails === undefined) {
        showDetails = false;
    }
    if (isExpanded === undefined) {
        isExpanded = false;
    }

    return (
        <>
            <ListItem onClick={action}>
                {(!isExpanded) && (showDetails) && <KeyboardArrowUp />}
                {(isExpanded) && (!showDetails) && <KeyboardArrowDown />}
                <ListItemDecorator>
                    <Avatar src={(visit.photo_url) ? `${domain}/photos/100/${visit.photo_url}` : `${domain}/photos/100/default-college-image.jpg`} onClick={handleClick}
                        alt="Click to view full screen"
                        sx={{ cursor: 'pointer', marginRight: "8px", }} // Change cursor to pointer for better UX

                    />
                </ListItemDecorator>
                <ListItemContent>
                    <Typography level="title-sm">{visit.college_name}-{visit.college_code}</Typography>
                    <Typography level="body-sm" noWrap>
                        Visit Date : <DF style={{ float: "left" }} dateString={visit.visit_date} sx={{ display: "floatleft" }} />
                    </Typography>
                    {(showDetails) && <Typography level='body-sm' noWrap>State : {visit.state} , District : {visit.district}</Typography>}
                    {(showDetails) && <Typography level='body-sm' noWrap>College Address : {visit.college_address}</Typography>}
                    {(showDetails) && <Typography level='body-sm' noWrap>College Website : {visit.college_website}</Typography>}
                    {(showDetails) && <Typography level='body-sm' noWrap>Univerisity Name : {visit.univerisity_name}-{visit.university_code}</Typography>}


                </ListItemContent>

                {(!isExpanded) && (!showDetails) && < KeyboardArrowRightIcon />}

            </ListItem>
            {/* Modal to show full-screen image with animation */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 0,
                    boxSizing: 'border-box',
                    opacity: isAnimating ? 1 : 0, // Smooth transition for visibility
                    visibility: open ? 'visible' : 'hidden', // Control visibility
                    transition: 'opacity 0.5s ease-out', // Ensure transition smoothness
                }}
            >
                <Box
                    sx={{
                        p: 2,
                        border: '1px dashed grey',
                        position: 'relative',
                        animation: `${isClosing ? shrinkAnimation : growAnimation} 0.5s ease-out`,
                        maxWidth: 'calc(100vw - 32px)',
                        maxHeight: 'calc(100vh - 32px)',
                        overflow: 'hidden',
                        padding: '16px',
                        borderRadius: '8px',
                    }}
                >
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: '#fff',
                            zIndex: 10,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <img
                        src={imageSrc}
                        alt="Full Screen"
                        style={{
                            maxWidth: '100%',
                            height: 'calc(100vh - 64px)',
                            objectFit: 'contain',
                            borderRadius: '8px',
                            display: 'block',
                            margin: '0 auto',
                        }}
                    />
                </Box>
            </Modal>
        </>
    );
}