import React, { useState, useContext } from "react";
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";
import domain from "../../util/domain";
import ErrorMessage from "../misc/ErrorMessage";

export default function ChangePassword() {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const { getUser } = useContext(UserContext);
    const navigate = useNavigate();

    async function changePassword(e) {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setErrorMessage("New passwords do not match");
            return;
        }

        const passwordData = {
            currentPassword,
            newPassword
        };

        try {
            await Axios.post(`${domain}/auth/change-password`, passwordData);
            await getUser();
            navigate("/");
        } catch (err) {
            if (err.response) {
                if (err.response.data.errorMessage) {
                    setErrorMessage(err.response.data.errorMessage);
                }
            }
        }
    }

    return (
        <main>
            <Sheet
                sx={{
                    width: 300,
                    mx: 'auto',
                    my: 4,
                    py: 3,
                    px: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 'sm',
                    boxShadow: 'md',
                }}
                variant="outlined"
            >
                <div>
                    <Typography level="h4" component="h1">
                        <b>Change Password</b>
                    </Typography>
                    {errorMessage && (
                        <ErrorMessage
                            message={errorMessage}
                            clear={() => setErrorMessage(null)}
                        />
                    )}
                </div>
                <form onSubmit={changePassword}>
                    <FormControl>
                        <FormLabel>Current Password</FormLabel>
                        <Input
                            name="currentPassword"
                            type="password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            required
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>New Password</FormLabel>
                        <Input
                            name="newPassword"
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Confirm New Password</FormLabel>
                        <Input
                            name="confirmPassword"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </FormControl>
                    <Button type="submit" sx={{ mt: 1 }}>Change Password</Button>
                </form>
            </Sheet>
        </main>
    );
}
