import React, { useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Card from '@mui/joy/Card';
import FoldableInput from '../../../inputs/FoldableInput';
import Typography from '@mui/joy/Typography';
import Divider from '@mui/joy/Divider';
import Checkbox from '@mui/joy/Checkbox';
import Sheet from '@mui/joy/Sheet';
import SearchDropdown from '../../../inputs/SearchDropdown';
import ImageCapture from '../../../inputs/ImageCapture'; // Import the new component
import axios from 'axios';
import domain from "../../../../util/domain";
import CapturedLocation from '../../../inputs/CapturedLocation';
import UserContext from "../../../../context/UserContext";
import AddIcon from '@mui/icons-material/Add';

export default function AddTPO({ details }) {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();
    const { visit } = location.state || {};
    console.log("TPOEdit: tpoDetails: ", visit);
    const visitDetails_id = visit.id;
    const college_id = visit.college_id;
    const createdBy = user.id;
    const [tponame, setTponame] = useState("");
    const [tpo_email, setTpo_email] = useState("");
    const [tpo_mobile, setTpo_mobile] = useState("");
    const [isStudentDataShared, setIsStudentDataShared] = useState(0);
    const [remarks, setRemarks] = useState("");
    const [branch, setBranch] = useState("");
    const [capturedPhoto, setCapturedPhoto] = useState(null);
    const [capturedNoticePhoto, setCapturedNoticePhoto] = useState(null);
    const [geoLocation, setGeoLocation] = useState({ lat: 0, lon: 0 });

    const handleNoticeCapture = (photo) => {
        setCapturedNoticePhoto(photo);
    }
    const handleCapture = (photo) => {
        setCapturedPhoto(photo);
    };

    const updateLocation = (location) => {
        console.log("location: ", location);
        setGeoLocation(location);
    };

    const batchCanged = (selectedOption) => {
        console.log("Selected Option: ", selectedOption);
        setBranch(selectedOption.branch);
    }
    const cancelAction = (e) => {
        e.preventDefault();
        navigate(-1);
    }
    const updateTPO = (e) => {
        e.preventDefault();
        axios.post(`${domain}/tpos/`, {
            tponame,
            tpo_email,
            tpo_mobile,
            isStudentDataShared,
            remarks,
            visitDetails_id,
            college_id,
            createdBy,
            branch,
            longitude: geoLocation.lon,
            latitude: geoLocation.lat,
            newPhoto: capturedPhoto,
            newNoticePhoto: capturedNoticePhoto,
        })
            .then((response) => {
                console.log("TPO ADD: response: ", response.data);
            })
            .catch((error) => {
                console.error("TPO ADD error: ", error);
            });
        navigate(-1);
    }

    return (
        <form>
            <Card>
                <Typography level="title-lg" startDecorator={<AddIcon />}>
                    {`Add TPO Details`}
                </Typography>
                <Divider inset="none" />

                <Sheet sx={{ height: '44px', paddingBottom: '10px' }}>
                    <SearchDropdown defaultOption={{ branch }} onBatchChange={batchCanged} />
                </Sheet>
                <FoldableInput inputType='text' labelText={`TPO Name`} placeHolderText={`TPO Name`} value={tponame} onChange={(e) => setTponame(e.target.value)} />
                <FoldableInput inputType='phone' labelText={`TPO Mobile`} placeHolderText={`TPO Mobile`} value={tpo_mobile} onChange={(e) => setTpo_mobile(e.target.value)} />
                <FoldableInput inputType='email' labelText={`TPO E-Mail`} placeHolderText={`TPO E-Mail`} value={tpo_email} onChange={(e) => setTpo_email(e.target.value)} />
                <FoldableInput inputType='textarea' labelText={`TPO Remarks`} placeHolderText={`TPO Remarks`} value={remarks} onChange={(e) => setRemarks(e.target.value)} />
                <Sheet variant="outlined" sx={{ height: '44px' }}>
                    <Checkbox overlay label="Is Student Data Shared?"
                        sx={{ alignContent: 'center', paddingLeft: '10px', marginTop: '13px' }}
                        checked={(isStudentDataShared === 0) ? false : true}
                        onChange={(e) => {
                            setIsStudentDataShared((e.target.checked) ? 1 : 0);
                        }}
                    />
                </Sheet>
                <CapturedLocation onUpdateLocation={updateLocation} />
                {/* Image Capture Component */}
                <ImageCapture title={`TPO Photo`} onCapture={handleCapture} />

                <ImageCapture title={`TPO Notice Board`} onCapture={handleNoticeCapture} />

                <button type="button" onClick={cancelAction}>Cancel</button>
                <button type="submit" onClick={updateTPO}>Save</button>
            </Card>
        </form>
    );
}