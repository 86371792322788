import React, { useContext, useState } from "react";
import Sheet from '@mui/joy/Sheet';
import CssBaseline from '@mui/joy/CssBaseline';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Link from '@mui/joy/Link';
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";
import domain from "../../util/domain";
import ErrorMessage from "../misc/ErrorMessage";



export default function Login() {
    const [formEmail, setFormEmail] = useState("");
    const [formPassword, setFormPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const { user, getUser } = useContext(UserContext);
    const history = useNavigate()

    console.log("user: ", user);
    async function userExists() {
        if (user) {
            history("/");
        }
    }

    async function login(e) {
        e.preventDefault();

        const loginData = {
            email: formEmail,
            password: formPassword,
        };

        try {
            await Axios.post(`${domain}/auth/login`, loginData);
        } catch (err) {
            if (err.response) {
                if (err.response.data.errorMessage) {
                    setErrorMessage(err.response.data.errorMessage);
                }
            }
            return;
        }

        await getUser();
        history("/");
    }

    return (
        <main>
            <CssBaseline />
            {(user) ? userExists() : null}
            <Sheet
                sx={{
                    width: 300,
                    mx: 'auto', // margin left & right
                    my: 4, // margin top & bottom
                    py: 3, // padding top & bottom
                    px: 2, // padding left & right
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 'sm',
                    boxShadow: 'md',
                }}
                variant="outlined"
            >
                <div>
                    <Typography level="h4" component="h1">
                        <b>College Visits Login.</b>
                    </Typography>
                    <Typography level="body-sm">Sign in to continue.</Typography>
                    {errorMessage && (
                        <ErrorMessage
                            message={errorMessage}
                            clear={() => setErrorMessage(null)}
                        />
                    )}
                </div>
                <form onSubmit={login}>
                    <FormControl>
                        <FormLabel>Email</FormLabel>
                        <Input
                            // html input attribute
                            name="email"
                            type="email"
                            value={formEmail}
                            placeholder="johndoe@email.com"
                            onChange={(e) => setFormEmail(e.target.value)}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Password</FormLabel>
                        <Input
                            // html input attribute
                            name="password"
                            type="password"
                            value={formPassword}
                            placeholder="password"
                            onChange={(e) => setFormPassword(e.target.value)}
                        />
                    </FormControl>
                    <Button type="submit" sx={{ mt: 1 /* margin top */ }}>Log in</Button>
                </form>
                <Link href="/forgetpass">Forget password?</Link>
            </Sheet>
        </main>
    );
}